<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="선택해주세요" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2> 개인 정보 보호 정책 </h2>


		<p>

			머리말 <br />

			저희는 개인정보가 귀하에게 얼마나 중요한지 잘 알고 있으며 법률과 법규의 요구에 따라 상응하는 안전 보호 조치를 취해 귀하의 개인정보를 안전하게 통제할 수 있도록 노력할 것입니다.PIX TECHNOLOGY
			PTE를 사용하고 있습니다.

			LTD사의 UniLive 플랫폼 서비스를 시작하기 전에 이 기본 기능의 개인 정보 보호 정책(이하 "본 개인 정보 보호 정책")을 자세히 읽고 충분히 이해하여 적절한 사용 선택을 하시기
			바랍니다.<br />

		</p>

		<p>

			이 개인 정보 보호 정책은 웹 사이트, 웹 페이지, 클라이언트 및 애플릿과 기술 발전에 따른 새로운 서비스 매개체를 포함하여 UniLive 소프트웨어의 서비스에만 적용됩니다.

		</p>

		<p>

			귀하가 본 프라이버시 정책에 대해 궁금한 점이 있으시면 본 프라이버시 정책의'저희에게 연락하는 방법'에 명시된 방식으로 저희에게 연락하실 수 있습니다. 저희는 기꺼이 답변해 드리겠습니다.또한 본 개인 정보
			보호 정책은 법률, 규정 및 비즈니스 개발 변화에 따라 수시로 개정되어 적절한 방법으로 알려드립니다.

		</p>

		<p>

			귀하가 만 14세 18세 미만의 미성년자이거나 지적, 정신건강 상태로 인해 완전한 민사행위 능력을 가지지 못한 경우 귀하의 법정후견인의 지도와 동반하에 본 프라이버시 정책을 공동으로 읽고
			동의하십시오.귀하가 만 14세 미만의 미성년자인 경우, 귀하의 법적 보호자가 귀하를 위해 본 개인 정보 보호 정책을 읽고 동의하시기 바랍니다.귀하와 귀하의 법적 보호자는 미성년자 보호 내용에 각별히
			주의하시기 바랍니다.

		</p>

		<h3> 본 개인 정보 보호 정책은 다음을 이해하는 데 도움이 됩니다</h3>

		<p> 1. 개인정보의 수집과 사용 </p>

		<p>2. 개인정보의 공유, 이전 및 공개</p>

		<p> 3. 개인정보의 보호 및 저장 </p>

		<p> 4, 귀하의 권리</p>

		<p>5, 미성년자 보호 </p>

		<p> 6. 프라이버시 정책 액세스 및 업데이트 </p>

		<p>7、저희에게 연락하는 방법</p>

		<h3> 1. 개인정보의 수집과 사용 </h3>

		<p>

			UniLive는 이 개인 정보 보호 정책에 동의하지 않을 경우 "동의하지 않음"을 클릭하여 게스트 모드로 들어가기 위해 일정 시간 이상 비디오 검색, 재생을 수행하지 않는 짧은 비디오 서비스를 제공합니다.

		</p>

		<p>

			또한 다양한 제품 및 / 또는 서비스를 제공합니다.귀하가 선택하여 사용하는 기능 및/또는 서비스를 실현하고 법률과 법규의 요구를 준수하기 위해 저희는 귀하의 상응하는 개인 정보를 수집, 사용하거나 해당하는
			필요한 권한을 열어야 합니다.거부하면 해당 기능 및 / 또는 서비스를 사용할 수 없게 되지만 다른 비관련 기능 및 / 또는 서비스의 정상적인 사용에는 영향을 주지 않습니다.귀하는 본 개인 정보 보호 정책의
			네 번째 부분인'귀하의 권리'를 통해 개인 정보와 권한 관리를 할 수 있습니다.

		</p>

		<p>

			귀하가 본 기본 기능의 개인 정보 보호 정책에 동의하는 경우 저희는 귀하가 UniLive 기본 기능의 사용에 동의하는 경우에만 필요한 개인 정보를 처리합니다.UniLive의 다른 확장 기능을 사용하는 경우
			특정 확장 기능을 사용할 때 별도의 동의를 구합니다.

		</p>

		<p>

			또한 특정 시나리오에서 즉시 알림 (탄창, 페이지 힌트), 기능 업데이트 설명을 통해 해당 정보 수집의 목적, 범위 및 사용 방법을 설명합니다.이 유형의 설명은 본 개인 정보 보호 정책의 일부를 구성하며
			본 개인 정보 보호 정책과 동등한 효력을 가진다.

		</p>

		<p>

			일반적으로 다음과 같은 UniLive 플랫폼 기능 및/또는 서비스를 구현하기 위해 귀하의 개인정보를 수집하고 사용하거나 관련 권한을 신청합니다.

		</p>

		<p>

			1. 계정 서비스

		</p>

		<p>

			Google의 다양한 기능 및/또는 서비스(리뷰, 좋아요, 즐겨찾기, 비디오 게시)를 사용하려면 UniLive 플랫폼에 등록, 로그인할 수 있는 정보를 제공하거나 적절한 권한을 부여해야 합니다.

		</p>

		<p>

			1.1 이메일: 이메일을 사용하여 UniLive 제품 및 서비스에 로그인하는 경우 이메일 주소, 암호를 필수 정보로 제공해야 합니다.

		</p>

		<p>

			2. 콘텐츠 탐색, 재생 및 검색

		</p>

		<p>

			2.1 콘텐츠 탐색 및 재생

			UniLive 플랫폼 콘텐츠를 탐색, 재생할 때 당사는 귀하의 장치 정보(OAID, AndroidID, IDFA) 및 개인 인터넷 기록(탐색, 클릭, 공유, 수집)을 기록하여 귀하가 연속성, 일관성 있는
			사용 경험을 제공하고 서비스 품질을 보장할 수 있도록 합니다.

		</p>

		<p>

			2.1.1 위치 관련 탐색: 귀하가 같은 도시 서비스를 사용할 때 저희는 귀하에게 위치 권한을 신청하여 귀하의 정확한 지리적 위치 정보를 수집하고 사용할 수 있습니다.귀하가 정확한 위치 정보를 허가하지
			않으면 위치의 정확성에 영향을 줄 수 있습니다. 저희는 대략적인 위치 정보(WLAN 액세스 포인트(SSID, BSSID), Bluetooth 및 기지국, 센서 정보, IP 주소 정보, 이 정보가 정확한
			위치 정보와 합칭되는 위치 정보), 귀하가 관련 기능 및/또는 서비스에서 제공한 위치 정보(계정 자료에 자주적으로 기입된 소재지, 귀하가 업로드한 내용과 관련된 정확한 위치 정보, 귀하의 위치 확인 및
			기타 기능, 귀하의 위치 정보를 사용하여 귀하의 위치를 확인하거나 귀하의 위치에 연결하여 귀하가 상기 링크, 귀하의 위치를 추가하거나 귀하가 상기 위해 위와 관련된 내용을 추가할 때 귀하의 위치에
			사용합니다.

		</p>

		<p>

			2.1.2 가로화면 재생: 가로화면 모드를 사용할 때 가속도, 중력, 방향 센서를 호출하여 센서 정보를 통해 장치 화면의 방향 변화를 감지하고 가로세로 화면을 전환해야 합니다.

		</p>

		<p>

			2.2 검색

		</p>

		<p>

			검색 기능을 사용할 때 장치 정보(OAID,AndroidID、IDFA）、명령 (텍스트, 음성 또는 그림) 및 로그 정보를 검색합니다.효율적인 검색 서비스를 제공하기 위해 키워드 정보는 로컬 장치에 잠시
			저장되며, 검색 아이콘을 클릭할 때 표시되며, 이러한 검색 기록을 전부 또는 부분적으로 삭제할 수도 있습니다.

			QR코드를 스캔하거나 상품을 검색하거나 사진을 검색할 때 카메라, 앨범 사용 권한을 부여받아 사진을 찍거나 앨범을 통해 사진을 업로드하여 검색할 수 있습니다.스캔을 사용할 때 광선 센서를 호출하여 화면
			밝기를 조정하고, 선형 가속도 센서를 호출하여 장치 방향과 각도 정보를 감지하여 스캔된 내용을 쉽게 식별할 수 있도록 합니다.귀하가 관련 권한을 여는 것에 동의하지 않으면 귀하가 우리의 기본 기능을
			사용하는 데 영향을 주지 않습니다.

		</p>

		﻿

		﻿

		<p>3. 소셜 커뮤니케이션</p>

		<p>

			우리는 당신에게 풍부한 사교 상호작용 기능을 제공합니다.귀하가 더 많은 친구를 빨리 알고 교류할 수 있도록 저희는 아래 설명에 따라 관련 정보를 수집하고 사용할 것입니다.

		</p>

		<p>

			3.1 콘텐츠 상호 작용

		</p>

		<p>

			귀하가 상호작용을 할 때 저희는 귀하의 상호작용 기록과 내용을 수집하고 공개합니다 (조회, 좋아요, 댓글, 관심, 수집, 전달, 공유, @ 기타 사용자).

		</p>

		<p>

			UniLive는 공유된 콘텐츠, 활동 정보를 공유하거나 받는 경우 클립보드에 액세스하여 포함된 비밀번호, 공유 코드, 링크를 읽어 점프, 공유, 복지 수령, 활동 연동 서비스를 실현하고 사용자가 관련
			콘텐츠를 열고 사용자 경험을 향상시킬 수 있도록 도와야 합니다.우리는 클립보드 내용을 로컬로 인식하고 UniLive 명령어에 속하는 경우에만 서버에 업로드합니다.또한 비디오, 사진을 공유하거나 저장할 때
			앨범을 읽어야 합니다.

		</p>

		<p>

			일부 장치는 앱이 클립보드나 앨범을 읽고 있다는 메시지를 표시할 수 있지만 로컬 처리인지 업로드인지 구분하지 않고 장치 통계 기준에 따라 오보가 발생할 수 있습니다.궁금한 점이 있으시면 저희에게 연락
			주십시오.

		</p>

		<p>

			3.2 메시지 알림

		</p>

		<p>

			귀하는 UniLive가 귀하가 권한을 부여받아 수집한 핸드폰 번호, 메일박스를 바탕으로 문자, 전화, 이메일, 시스템 알림을 통해 귀하가 관심을 가질 수 있거나 필요한 관련 내용, 활동, 서비스, 기능
			정보를 제공하여 귀하가 신분증, 안전 검증, 사용자 사용 체험 조사를 하는 것을 도울 수 있음을 알고 동의합니다.귀하가 관련 푸시 정보를 받아들이지 않을 경우 귀하는 본 프라이버시 정책의 네 번째
			부분인'귀하의 권리'에서 제공한 경로를 통해 관련 설정을 할 수 있습니다.

		</p>

		﻿

		﻿

		<p>4. 콘텐츠 촬영, 제작 및 출시 </p>

		<p>

			4.1 촬영, 제작

		</p>

		<p>

			짧은 비디오, 라이브 도구를 사용하여 오디오 비디오, 사진 콘텐츠를 촬영, 제작하는 경우 사진첩 업로드 및 로컬 동기화 저장을 위해 촬영, 녹화, 오디오 녹화를 위해 사진첩 / 저장 권한 (SD 카드
			포함) 을 사용하여 사진 업로드 및 로컬 동기화 저장을 위한 카메라 켜기 권한 및 마이크 권한을 요청합니다.귀하가 선택하여 업로드한 오디오 비디오, 사진 내용만 업로드할 뿐 다른 정보는 업로드하지 않는다는
			것을 알아주십시오.이와 동시에 광선을 감시하고 밝기를 조절하여 촬영화면의 각도를 조절하기 위하여 우리는 당신의 설비에 광선센서, 가속센서를 호출하게 된다.이 절차에서 모든 권한 또는 일부를 승인하도록
			선택할 수 있습니다.귀하가 관련 권한을 여는 것에 동의하지 않으면 귀하가 우리의 기본 기능을 사용하는 데 영향을 주지 않습니다.

		</p>

		<p>

			4.2 출시

		</p>

		<p>

			귀하가 도문, 동영상 내용을 공개적으로 발표할 때, 저희는 관련 내용을 수집하여 귀하의 프로필 사진, 닉네임 및 상술한 내용 정보를 공개적으로 전시할 것입니다.

		</p>

		<p>

			4.2.1 빠른 업로드: 출시 예정 작품의 업로드 속도, 풍부한 출시 기능, 최적화 경험 및 트래픽 절약을 위해 촬영이 완료된 후 "출시" 버튼을 클릭하기 전에 장치가 WLAN에 연결된 상태에서 업로드를
			시작하여 서버에 임시로 로드합니다.그러나 이 기능은 해당 작품을 미리 게시하지 않으며, "게시" 버튼을 클릭해야만 게시를 제출할 수 있다는 것을 알고 주십시오.게시를 취소하거나 다른 이유로 업로드에
			성공하지 못한 경우 즉시 관련 컨텐트를 삭제합니다.귀하는 본 개인 정보 보호 정책의 네 번째 부분인 귀하의 권리에서 제공한 경로를 통해 이 기능을 종료할 수 있습니다.

		</p>


		<p>5. 개인화 권장사항 </p>

		<p>

			5.1 맞춤형 추천 서비스

			귀하에게 관련성이 높은 콘텐츠를 전시, 추천하고 귀하의 요구에 부합하는 서비스를 제공하기 위해 저희는 귀하가 UniLive 플랫폼 서비스를 사용하는 과정에서 조회, 재생, 검색 기록을 수집하고 사용하며
			법에 따라 수집된 장치 정보(OAID,AndroidID、IDFA）、로그 정보, 기타 귀하가 권한을 부여한 정보는 귀하의 선호 특징을 바탕으로 귀하가 관심을 가질 수 있는 페이지, 동영상, 생방송 내용과
			검색 결과, 알 수 있는 친구, 상업 광고, 서비스, 기능을 귀하에게 전달합니다.귀하가 귀하의 위치 정보에 따라 첫 페이지, 생방송 또는 쇼핑몰 페이지에서 개성화된 추천을 할 수 있는 권한을 부여하면
			저희는 해당 기능에서 귀하의 위치 정보를 수집하여 개성화된 추천을 할 것입니다.


		</p>

		<p>

			5.2 맞춤형 권장 운영 메커니즘

		</p>

		<p>

			개인화 추천은 알고리즘 모델을 바탕으로 귀하의 선호 특징을 예측하고 귀하가 흥미를 가질 수 있는 내용, 서비스 또는 기타 정보와 일치하며 귀하에게 보여주는 이 정보를 정렬합니다.UniLive 플랫폼
			서비스를 사용하는 동안 개인 인터넷 기록을 기반으로 추천 모델에 대한 실시간 피드백을 통해 최적화된 추천 결과를 지속적으로 조정합니다.고객의 다양한 요구를 충족시키기 위해 정렬 과정에서 다양한 추천 기술을
			도입하여 같은 유형의 콘텐츠가 과도하게 집중되지 않도록 추천 콘텐츠를 확장합니다.

		</p>


		<p>6. 고객 서비스 </p>

		<p>

			귀하에게 고객 서비스를 제공하기 위해 저희는 귀하가 필요한 개인 정보를 제공하여 귀하의 계정을 안전하게 해야 할 수도 있습니다.귀하에게 쉽게 연락하고 가능한 한 빨리 문제를 해결하거나 관련 문제의 처리
			방안과 결과를 기록하기 위해 저희는 다음과 같은 관련 정보를 수집할 수 있습니다. 연락처(귀하가 저희에게 연락할 때 사용하는 전화번호/이메일이나 귀하가 저희에게 주동적으로 제공한 다른 연락처), 귀하의
			의사소통 정보(귀하가 저희와 또는 관련자와의 문자/사진/음성영상/통화기록), 그리고 기타 필요한 정보(계좌정보, 주문정보, 귀하가 관련 사실을 증명하기 위해 제공한 기타 정보).

		</p>

		<p>

			서비스를 제공하고 서비스 품질을 개선하는 합리적인 수요를 위해 저희는 귀하가 고객센터에 연락할 때 제공한 관련 정보, 설문 조사에 참여할 때 저희에게 보낸 설문 답변 정보를 사용할 수 있습니다.

		</p>


		<p>7. APP 적합 및 운영 보안 </p>

		<p>

			7.1 귀하에게 양질의 신뢰할 수 있는 콘텐츠, 서비스를 제공하고 제품과 기능의 정상적인 운영을 유지하기 위해 부정행위, 사기 방지로 귀하 또는 다른 사용자 또는 대중의 안전과 합법적인 이익을 보호하고
			악성 프로그램, 낚시 사이트, 네트워크 취약점, 컴퓨터 바이러스, 인터넷 공격, 인터넷 침입 안전 위험을 예방하며 법률, 법규 또는 UniLive 플랫폼의 관련 규칙을 위반하는 상황을 더욱 정확하게
			식별하기 위해 저희는 귀하의 안전, 인증, 계정, 인증, 인증, 개인 정보 수집, 사용 및 수집 정보의 안전 정보, 인증 및 수집, 인증 및 기타 파트너 정보의 안전 정보의 수집, 인증, 인증 및 수집
			정보의 안전 정보에 대한 인증, 인증 및 수집을 위해 귀하의 계정을 통해 귀하의 계정을 보호하고 합법적인 정보를 수집하고 합법적인 정보를 수집합니다. 필요한 기록, 분석,조치를 처분하다.사용자 행동이 위험
			제어 조건을 트리거하는 경우에만 (암호 오류, 잦은 오프사이트 로그인, 위험 계정, 결제 금액 이상, 현금 인출 행위 이상) 설치한 앱 정보 (패키지 이름) 또는 실행 중인 프로세스 정보, 앱의 전체
			실행, 사용 및 빈도, 앱 충돌 상황, 전체 설치 사용, 성능 데이터, 앱 소스에 액세스하고 매우 일반적인 앱의 패키지 정보만 업로드합니다.

		</p>

		<p>

			장치 정보: 장치 식별 정보(AndroidID, IDFA, IDFV, UAID(중국 모바일 UAID, 차이나유니콤 UAID 및 차이나텔레콤 UAID, 안드로이드 장치만 해당), OAID, Openudid
			및 기타 종합적인 장치 매개 변수와 시스템 정보로 이루어진 장치 식별자, 유효기간, 사용자 재설정 가능 여부 및 접근 방식 및 시스템 유형에 따라 다름), 네트워크 정보, 하드웨어 정보, 네트워크
			운영체제, 소프트웨어 정보, 네트워크 운영체제, 언어 정보, 네트워크 운영체제, 소프트웨어 정보, 소프트웨어 정보, 소프트웨어 정보, 소프트웨어 정보, 소프트웨어 정보, 소프트웨어 정보, 소프트웨어 정보,
			네트워크 운영체제, 소프트웨어 정보, 소프트웨어 정보,).

		</p>

		<p>

			로그 정보: 조작, 사용된 개인 인터넷 기록 (탐색, 재생, 검색, 좋아요, 관심, 수집, 평론, 다운로드, 공유, 거래).

		</p>

		<p>

			UniLive를 장치 백그라운드로 전환하여 실행할 때 일부 장치 모델 및 시스템 때문에 이전에 UniLive 관련 기능을 사용하여 발생한 정보 수집 행위가 즉시 중지되지 않아 일시적인 백그라운드 정보 수집
			행위가 발생할 수 있음을 이해하십시오.

		</p>

		<p>

			7.2 운영의 질과 효율을 향상시키기 위해 연구, 통계 분석을 할 때 저희는 상기 개인정보를 식별화 처리하고 귀하의 실제 신분과 관련되지 않습니다.상술한 탈표식화
		</p>

		<h3> 3. 개인정보의 보호 및 저장 </h3>

		<p>(1) 정보 보안 시스템 보증</p>

		<p>

			정보 보안을 유지하기 위해 Dell은 업계 표준에 부합하는 다양한 물리적 / 전자 / 관리 보안 조치 (SSL, 정보 암호화 스토리지, 데이터 센터 액세스 제어) 를 통해 귀하의 정보가 유출되거나
			손상되거나 손실되지 않도록 보호하기 위해 노력하고 있습니다.우리는 조직 건설, 제도 설계, 인력 관리, 제품 기술, 개인 정보 보호 영향 평가 다차원에서 UniLive 서비스 시스템의 안전성을 향상시키는
			업계 선진적인 데이터를 핵심으로 하고 데이터 생명 주기를 중심으로 하는 데이터 안전 관리 체계를 가지고 있다.

		</p>

		<p>(2) 정보 안전 관리 제도</p>

		<p>저희는 전문적인 안전 부서, 안전 관리 제도, 데이터 안전 절차를 구축하여 귀하의 개인 정보를 안전하게 보호합니다.저희는 엄격한 데이터 사용과 접근 제도를 채택하여 권한을 부여받은 사람만이 귀하의 개인
			정보에 접근할 수 있도록 합니다.정기적으로 보안 교육 및 교육을 실시하고 데이터 및 기술에 대한 보안 감사를 실시합니다.</p>

		<p>

			이와 동시에 우리는 또 UniLive 안전응급대응센터를 설립하고 전문적인 안전기술과 운영팀이 유지보수를 책임지여 제때에 효과적으로 각종 안전허점과 돌발사건에 호응하고 처리하는데 편리하며 관련 부문과
			련합하여 안전사건의 근원과 타격을 진행한다.개인정보 보안 사건이 발생하면 저희는 법률과 법규의 요구에 따라 귀하께 보안 사건의 기본적인 상황과 가능한 영향, 저희가 이미 취했거나 취할 처리 조치, 귀하가
			자발적으로 위험을 예방하고 낮출 수 있는 건의, 귀하에 대한 보완 조치를 알려드립니다.이벤트 관련 사항은 이메일, 편지, 전화, 푸시 알림 방식으로 알려드립니다.개인 정보 주체에게 일일이 알리기 어려울 때
			우리는 합리적이고 효과적인 방식으로 공고를 발표한다.이와 동시에 우리는 또 관련 감독관리부문의 요구에 따라 데이터루설 등 안전사건의 처리상황을 상부에 보고하게 된다.

		</p>

		<p>(3) 계정 보안 위험 방지</p>

		<p>

			특정 서비스를 사용할 때 귀하는 불가피하게 거래 상대방이나 잠재적인 거래 상대방에게 자신의 개인 정보(전화, 은행 계좌 정보)를 공개해야 합니다.귀하는 자신의 개인정보를 잘 보호하고 필요한 상황에서만
			타인에게 제공하며 제3자 제품이나 서비스를 통해 거래하는 것을 경솔하게 믿지 마십시오. 정보가 절취되거나 심지어 전신 인터넷 사기를 당하지 않도록 하십시오.

		</p>

		<p>(4) 정보 저장소</p>

		<p>1. 스토리지 사이트 </p>

		<p>UniLive는 법률, 법규의 규정에 따라 중국 내 운영 과정에서 수집하고 발생한 개인정보를 중국 내에 저장한다.귀하의 개인 정보 저장 장소가 중국 내에서 해외로 이전되는 경우, 저희는 엄격히 법률의
			규정에 따라 집행하거나 귀하의 단독 동의를 얻을 것입니다.</p>

		<p>2. 스토리지 기간 </p>

		<p>일반적으로 저희는 목적을 달성하기 위해 필요한 시간과 법률, 규정에 규정된 보존기한 동안에만 귀하의 개인정보를 보존하고 상기 저장기한을 초과하면 귀하의 개인정보를 삭제하거나 익명화 처리합니다.</p>

		<p> UniLive 플랫폼이 서비스 또는 운영을 종료하는 경우, 저희는 사전에 운영 중지 통지를 송달 또는 공지로 귀하에게 통지하고 서비스 종료 또는 운영 종료 후 합리적인 기한 내에 귀하의 개인 정보를
			삭제하거나 익명으로 처리합니다. </p>



		<h3> 4, 귀하의 권리</h3>

		<p>

			저희는 귀하가 개인정보의 주체로서 누리는 권리를 매우 중시하고 귀하의 개인정보에 대한 통제권을 보호하기 위해 최선을 다합니다.UniLive 계정의 등록, 로그인을 완료한 후 귀하는 다음과 같은 방식으로
			관련 권리를 행사할 수 있습니다(각 관련 제품의 권리 행사 경로가 일치하지 않을 수 있으며 실제 상황을 기준). 저희는 법률과 법규의 요구에 부합되는 상황에서 귀하의 요청에 응답할 것입니다.또한 귀하는 본
			개인 정보 보호 정책의'연락처'에 명시된 방식으로 저희에게 연락하여 귀하의 관련 권리를 행사할 수 있습니다.

		</p>

		<p>(1) 개인정보 열람, 복사, 전송</p>

		<p>UniLive를 통해 개인 정보를 열람, 복사, 전송할 수 있습니다.</p>

		<p>(2) 개인정보 정정, 보충</p>

		<p>귀하는 저희가 처리한 귀하에 대한 개인정보가 정확하지 않거나 완전하지 않은 것을 발견하면 귀하의 신분을 검증한 후에 귀하는 피드백과 오보를 통해 귀하의 정정 또는 보충 신청을 저희에게 제출할 수
			있습니다.</p>

		<p>일반적으로 귀하는 언제든지 제출된 정보를 수정하고 보충할 수 있지만 보안 및 인증(사서함 불만 서비스)을 고려하여 등록 시 제공되는 일부 초기 등록 정보와 인증 정보는 변경할 수 없습니다. </p>

		<p>(3) 개인정보 삭제</p>

		<p>귀하는 상술한 "(2) 개인정보 정정, 보충"에 열거된 경로를 통해 귀하의 일부 정보를 삭제하거나 계정 탈퇴를 신청하여 귀하의 모든 정보를 삭제할 수 있습니다.</p>

		<p>다음의 경우 개인 정보 삭제를 요청할 수도 있습니다.</p> 

		<p>1. 처리 목적이 달성되었거나 달성할 수 없거나 더 이상 필요하지 않은 경우 </p>

		<p>2. 제품 또는 서비스 제공을 중지하거나 보존 기간이 만료된 경우 </p>

		<p>3. 귀하는 동의를 철회합니다; </p>

		<p>4. 법률과 법규를 위반하거나 약정을 위반하여 개인정보를 처리한다. </p>

		<p>5. 법률 및 규정에 따른 기타 상황. </p>

		<p>당사는 귀하가 당사 서비스에서 정보를 삭제한 후 관련 법률 및 보안 기술 제한으로 인해 해당 정보를 즉시 백업 시스템에서 삭제하지 않을 수 있으며, 백업이 삭제되거나 익명화될 때까지 저장과 필요한 보안
			조치 이외의 처리를 중지할 것입니다.</p>

		<p>(4) 동의 철회 및 권한 변경</p>

		<p>

			각 기능 및/또는 서비스는 기본적인 개인 정보가 있어야 완료할 수 있다는 것을 이해해 주십시오. 귀하가 동의를 철회한 후에 저희는 귀하에게 동의를 철회하는 데 대응하는 기능 및/또는 서비스를 계속 제공할
			수 없고 귀하의 상응하는 개인 정보를 더 이상 처리하지 않습니다.그러나 귀하의 동의 철회 결정은 철회 전 개인의 동의에 따라 이미 진행된 개인정보 처리 활동의 효력에 영향을 주지 않습니다.

		</p>

		<p>(5) 계정 로그아웃 </p>

		<p>

			당신은 신청을 통해 당신의 계정을 말소할 수 있습니다.UniLive는 해당 사용자 또는 해당 사용자가 권한을 부여한 주체가 계정 말소를 신청할 수 있도록 사용자와 신청자의 신원 정보를 수집하고 검증하여
			사용자의 권익을 보호하기 위해 최선을 다할 것이다.UniLive는 개인정보보호에 관한 법률, 법규의 규정을 엄격히 준수하며 상기 정보는 이번 계정말소에만 사용된다.

		</p>

		<p>UniLive 특별 팁, 계정을 로그아웃하는 행위는 되돌릴 수 없습니다.법률과 규정에 별도로 규정된 경우를 제외하고 귀하가 계정을 탈퇴한 후에 저희는 귀하와 관련된 개인정보를 삭제하거나 익명화할
			것입니다. 만약에 개인정보를 삭제하는 것이 기술적으로 어려운 경우 저희는 저장과 필요한 안전 조치를 취하는 것을 제외한 처리를 중지할 것입니다. </p>

		<p>(6) 고인의 계정 권익</p>

		<p>

			만약 UniLive 사용자가 불행히 서거하고 말소되지 않은 계정은 사용권자가 사망한후 추모계정으로 될수 있으며 UniLive는 여전히 추모계정중의 사용자개인정보의 안전을 계속 보호할것이다.고인의 가까운
			친척은 본 프라이버시 정책의"어떻게 우리에게 연락할 것인가"에 명기된 방식을 통해 우리에게 연락할 수 있으며, 고인의 생전 안배를 위반하지 않고 타인과 대중의 정당한 이익을 침해하지 않는 전제하에 신분
			검사 수속을 완료한 후 고인의 개인 정보를 열람, 복제, 정정, 삭제하여 법률 법규가 규정한 합법적이고 정당한 권익을 행사할 수 있다.

		</p>




		<h3> 5. 미성년자 보호 </h3>

		<p>

			우리는 국가 관련 법률, 법규의 규정에 따라 미성년자의 개인정보를 보호하며 법률이 허용하거나 부모 또는 기타 후견인이 미성년자를 명확히 동의하거나 보호하는데 필요한 경우에만 미성년자의 개인정보를 수집,
			사용, 공유, 이전하고 공개한다.만약 우리가 사전에 실증할수 있는 부모 또는 기타 후견인의 동의를 얻지 않고 미성년자의 개인정보를 수집한것을 발견하면 관련 정보를 삭제하게 된다.이와 동시에 우리는 엄격한
			미성년자정보수집사용규칙을 구축하여 아동(만 14세 미만)과 청소년(만 14세 미만이지만 만 18세 미만)의 개인정보를 안전하게 보호하였다.

			귀하가 미성년자의 보호자인 경우 귀하가 보호하는 미성년자의 개인 정보에 대해 궁금한 점이 있을 때 본 프라이버시 정책인'저희에게 연락하는 방법'에 기재된 방식으로 저희에게 연락할 수 있습니다.

		</p>




		<h3> 6. 프라이버시 정책 액세스 및 업데이트 </h3>

		<p>(1) 본 개인 정보 보호 정책에 액세스</p>

		<p>1. 저희는 귀하가 UniLive 계정을 처음 등록할 때 귀하께 본 프라이버시 정책을 제시하고 귀하가 동의하는지 여부를 결정합니다. </p>

		<p>(2) 본 개인 정보 보호 정책 업데이트 </p>

		<p>1. UniLive는 본 개인 정보 보호 정책을 적시에 개정할 수 있으며 귀하의 명확한 동의 또는 법률과 법규의 관련 규정을 준수하지 않으면 본 개인 정보 보호 정책에 따라 귀하가 누려야 할 권리를
			삭감하지 않습니다. </p>

		<p>2. UniLive 개인 정보 보호 정책이 업데이트된 후 UniLive는 사용자가 로그인할 때 사이트 내 편지 또는 기타 적절한 방법으로 최신 버전을 보여줌으로써 귀하가 본 개인 정보 보호 정책의 최신
			내용을 즉시 이해할 수 있도록 합니다.</p>

		<p>3. 중대한 변경에 대하여 저희는 현저한 통지를 제공할 것입니다 (우편함, 사신, 시스템 통지, 탄창 제시). </p>



		<h3> 7. 저희에게 연락하는 방법 </h3>

		<p>개인 정보 보호 전담팀과 담당자를 두었습니다.개인정보 보호와 관련된 질문, 불만, 의견 또는 제안이 있는 경우 </p>

		<p>1. 다음으로 메일 보내기:unilive.team@gmail.com</p>

		<p>UniLive는 가능한 한 빨리 관련 문제를 검토하고 귀하의 신원을 확인한 후 즉시 회신할 것입니다.일반적으로 15영업일 이내에 귀하의 요청에 응답합니다.</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/PrivacyPolicyZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/PrivacyPolicyEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/PrivacyPolicyKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/PrivacyPolicyJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/PrivacyPolicyRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/PrivacyPolicyVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/PrivacyPolicyTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/PrivacyPolicyZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/PrivacyPolicyEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/PrivacyPolicyKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/PrivacyPolicyJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/PrivacyPolicyRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/PrivacyPolicyVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/PrivacyPolicyTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}
</style>